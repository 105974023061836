* {
  font-family: 'Poppins', sans-serif;
}
.news-card {
  width: 100%; /* adjust width */
}
p.small-text {
  color: rgb(4, 0, 24);
  font-size: 12px;
}
figure {
  width: 100%; /* adjust width */
  height: 120px; /* image container height */
  overflow: hidden;
  position: relative;
  margin-bottom: 0;
}
figure img {
  display: block;
  width: 100%;
  height: auto;
  transform-origin: 50% 50%;
  position: absolute;
}
.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* number of lines to show */
}
.ellipsis-1{
  -webkit-line-clamp: 1 !important;
}
.category {
  font-size: 10px;
  text-transform: uppercase;
}
.author-pdate {
  font-size: 10px;
}
.author-pdate .icon {
  width: 12px;
  height: 12px;
  overflow: hidden;
}
.author-pdate .icon img {
  width: 12px;
  height: 12px;
}

.scrolling-content {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.news-card-7 {
  width: 300px; /* adjust width */
}
.news-card-7 figure {
  height: 80px;
}
.news-card-4 .text-area {
  height: 80%;
}
.news-card-4 .author-pdate {
  bottom: 0;
}
.news-card-6 {
  height: 250px;
  overflow: hidden;
}
.card-img-overlay {
  top: auto !important;
}
.card-img-overlay * {
  color: white;
}
.carousel {
  background-color: #fff;
}
.carousel-indicators {
  bottom: -24px !important;
  background-color: #fff;
  margin-right: 0 !important;
  margin-left: 0 !important;
}
.carousel-indicators li {
  height: 1px !important;
  background-color: rgb(36, 36, 36) !important;
}
