.appmaker-chat-widget button {
  width: 3.6rem;
  height: 3.6rem;
  background: #2392ec;
  position: fixed;
  z-index: 999999999999999;
  bottom: 1rem;
  right: 1rem;
  border-radius: 50%;
  outline: none;
  border: none;
  font-size: 1.5rem;
}
.appmaker-chat-widget .widget-chat-screen {
  position: fixed;
  z-index: 9999999999;
  bottom: 5rem;
  right: 1rem;
  background: #fff;
  width: 350px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 5px 40px;
  border-radius: 0.5rem;
  max-height: 100vh;
  overflow: hidden;
  min-height: 70%;
  animation: revealAnimation 20ms 0s ease-in-out;
}
@keyframes revealAnimation {
  from {
    transform: translateY(10%);
  }
  to {
    transform: translateY(0);
  }
}
