.dialogue-glass {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #486997;
  z-index: 10001;
  opacity: 0.25;
  display: none;
  -webkit-transition: opacity 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out;
}
.support-sidebar {
  -webkit-transition: -webkit-transform 0.5s cubic-bezier(0.3, 1, 0.32, 1);
  transition: -webkit-transform 0.5s cubic-bezier(0.3, 1, 0.32, 1);
  transition: transform 0.5s cubic-bezier(0.3, 1, 0.32, 1);
  transition: transform 0.5s cubic-bezier(0.3, 1, 0.32, 1),
    -webkit-transform 0.5s cubic-bezier(0.3, 1, 0.32, 1);
  -webkit-transform: translateX(110%);
  transform: translateX(110%);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  position: fixed;
  width: 400px;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 8px;
  margin: 16px;
  background-color: #fff;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 10001;
  -webkit-box-shadow: -1px 0 12px 0 rgba(0, 0, 0, 0.08);
  box-shadow: -1px 0 12px 0 rgba(0, 0, 0, 0.08);
}
.support-sidebar.show {
  display: inline-block;
  transform: translateX(0px);
  z-index: 10009;
}
.dialogue-glass.show {
  display: inline-block;
}
@media (max-width: 575.98px) {
  .support-sidebar {
    width: 90%;
  }
}
