input.plan-check-box:checked + label.choose-plan-container h4 i.fas {
  color: #2bce67;
}
input.plan-check-box:checked + .choose-plan-container {
  border-color: #4b6efc;
}
input.plan-check-box:checked + .choose-plan-container h6 {
  border-color: #4b6efc;
}
input.plan-check-box:disabled + .choose-plan-container {
  opacity: 0.7;
}
.choose-plan-container {
  background-color: #f5f7fb;
  border-radius: 6px;
  text-align: center;
  border: 1px solid #e8eef9;
  height: 94%;
  padding: 0.5rem !important;
  width: 100%;
}
.choose-plan-container .plan-content {
  height: inherit;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 0.8rem;
}
.choose-plan-container h6 {
  border-bottom: 1px solid #e8eef9;
  padding-bottom: 6px;
  font-weight: 600;
}
.choose-plan-container h4 {
  padding-top: 12px;
  margin-bottom: 0px;
}
.choose-plan-container h4 small {
  font-weight: bold;
  font-size: 14px;
}
.choose-plan-container p.revenue-share {
  line-height: 16px !important;
  font-size: 12px;
}
.choose-plan-container p {
  padding: 10px 0;
  line-height: 16px;
  font-size: 14px;
}
.choose-plan-container h4 i.fas {
  color: #d5d6d9;
}
.btn-pill {
  border-radius: 20px !important;
}
.choose-plan-footer {
  border-radius: 0 0 8px 8px;
}
@media (max-width: 575.98px) {
  .choose-plan-container {
    padding: 1.5rem !important;
  }
  .modal-footer {
    flex-wrap: wrap;
  }
}
