.profile-card .profile-content .profile-title::before {
  content: '';
  position: absolute;
  height: 100%;
  width: 6px;
  background-color: red;
  display: block;
}
.profile-card .profile-image .image-container {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 1px solid #202020;
  overflow: hidden;
  object-fit: fill;
}
.profile-card .profile-image .image-container {
  display: flex;
  justify-content: center;
}
.profile-card .profile-image .image-container img {
  height: 120px;
  width: auto !important;
}
