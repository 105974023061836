/**
 * #.# Styles
 *
 * CSS for both Frontend+Backend.
 */

.components-placeholder {
  background: url('https://storage.googleapis.com/stateless-appmaker-pages-wp/2019/12/3c82c723-placeholder.png')
    no-repeat;
  background-size: cover;
  background-position: center;
  margin-bottom: 0;
}
.components-placeholder__label {
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.block-input {
  display: block;
  margin: 0.5rem 0;
}
.editor-block-list__layout {
  margin: auto;
  border: 1px solid #f3f3f3;
  padding: 0;
  background-color: white;
  min-height: 480px;
}
.editor-inner-blocks .editor-block-list__layout {
  min-height: fit-content;
}
.editor-block-list__layout *:focus {
  outline: none;
}
.block-list-appender {
  margin: 25px 10px;
}
.editor-block-mover {
  opacity: 1 !important;
}
.editor-block-list__block {
  margin: 5px 0;
}
/* .editor-block-list__block + .is-selected,
.editor-block-list__block + .is-typing {
  margin: 25px 14px 18px 18px !important;
} */
.editor-post-title {
  display: none;
}
.image-container {
  overflow: hidden;
  position: relative;
}
.image-container img {
  width: 100%;
}
.image-container .image-title {
  position: absolute;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  left: 0;
  bottom: 0px;
  color: #fff;
  padding: 5px;
}
.menu-image-container {
  padding: 4px;
}
.hover-pointer {
  cursor: pointer;
}
.editor-inserter.block-editor-inserter button {
  color: #4b6efc;
  background-color: #f5f7fb;
  border: 1px dashed #4b6efc;
  border-radius: 4px;
  outline: none;
  display: block;
  text-transform: uppercase;
  padding: 50px 0;
}
.editor-block-list__block {
  margin: 0 !important;
}
.block-editor-block-list__block
  > .block-editor-block-list__block-edit
  > .block-editor-block-mover {
  left: -35px !important;
  top: 0 !important;
}
.block-editor-block-list__block > .block-editor-block-list__insertion-point {
  display: none;
}
.editor-block-list__block .block-list-appender {
  display: contents;
}
.editor-block-list__block .block-list-appender button {
  display: block;
  margin: 10px;
  width: 100%;
  width: -moz-available; /* WebKit-based browsers will ignore this. */
  width: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  width: stretch;
  border-radius: 4px;
  border: 1px dashed #000d1b;
  background-color: #f5f7fb;
  color: #000d1b;
  outline: none;
  max-height: 200px;
}
.block-list-appender button span.screen-reader-text {
  margin-right: 10px;
}
label.screen-reader-text {
  padding: 16px 16px 0 16px;
}
@media (min-width: 600px) {
  .block-editor-block-list__block::before {
    left: 0 !important;
    right: 0 !important;
  }
  .block-editor-block-list__layout
    .block-editor-block-list__block
    .block-editor-block-list__block-edit::before {
    right: 0 !important;
    left: 0 !important;
    top: 0 !important;
    bottom: 0 !important;
  }
  .block-editor-block-contextual-toolbar .block-editor-block-toolbar {
    left: 15px !important;
    top: 15px !important;
  }
}
/*To remove the dot in the drag handle */
.editor-block-mover__control-drag-handle {
  display: none;
}
