.menu-view {
  height: calc(100vh - 80px);
}
.rstcustom__rowTitle {
  font-weight: 500;
}
.rstcustom__rowContents {
  box-shadow: none;
  border-radius: 4px;
  border: solid rgb(208, 227, 255) 1px;
  position: relative;
}
.tree-menu-item img {
  margin-right: 5px;
}
.menu-title {
  font-size: 14px;
  color: #272727;
}
.rst__tree {
  height: 90% !important;
}
