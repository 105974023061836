.smart-banner-preview-container {
  position: relative;
  height: 400px;
  overflow: hidden;
}
#appmaker-smart-banner.appbanner-main-container {
  position: absolute;
  display: flex;
  font-family: sans-serif;
  background-color: #fcfcfc;
  height: 70px;
  transition: all 0.25s ease;
  box-shadow: 0 1px 5px rgba(213, 212, 255, 0.747);
  width: 330px;
  top: 99px;
  left: 60px;
}

#appmaker-smart-banner .appbanner-container {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 5px;
}

#appmaker-smart-banner .close-accessibility-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 50px;
  height: 100%;
}

#appmaker-smart-banner img.apmkr-img {
  width: 46px;
  height: 46px;
  border-radius: 5px;
}

#appmaker-smart-banner .appbanner-content-container {
  margin: 0 8px;
  padding: 12px 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

#appmaker-smart-banner .appbanner-content-container .appbanner-discription {
  opacity: 0.8;
}

#appmaker-smart-banner .appbanner-button-container {
  justify-content: end;
  align-items: center;
  margin-right: 14px;
  margin-left: auto;
}

#appmaker-smart-banner .appbanner-button-container button {
  background-color: #113484;
  padding: 10px 15px;
  color: #fff;
  border-radius: 4px;
  border: none;
  font-size: 13px;
}
