.wp-block-appmaker-productscroller > div > div {
  overflow: auto;
  white-space: nowrap;
  display: flex;
}
.wp-block-appmaker-productscroller .product-scroller {
  padding: 5px;
}
.product-scroller-container {
  min-height: 190px;
  padding-bottom: 10px;
}
.wp-block-appmaker-productscroller > div > div > div {
  width: fit-content;
}
.wp-block-appmaker-productscroller > div > div > div > * {
  width: inherit;
}
.wp-block-appmaker-productscroller .block-list-appender {
  display: block;
  margin: auto;
}
div[data-type="appmaker/productscroller-grid-item"] {
  width: 50%;
  padding: 0.25rem;
}
.wp-block-appmaker-productscroller-grid .editor-inner-blocks.block-editor-inner-blocks .editor-block-list__layout.block-editor-block-list__layout{
  display: flex;
  flex-wrap: wrap;
}
.wp-block-appmaker-productscroller-grid .editor-inner-blocks.block-editor-inner-blocks .editor-block-list__layout.block-editor-block-list__layout .block-list-appender{
  display: block;
  width: 100%;
}