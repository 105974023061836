.appmaker-card {
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(208, 216, 231, 0.75);
  border-radius: 8px;
  overflow: hidden;
}
.appmaker-card-header {
  padding: 1.3rem 1.8rem;
  border-bottom: 1px solid #f5f7fb;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.appmaker-card-content {
  padding: 1.3rem 1.8rem;
}
.data-accordion {
  background: rgba(245, 247, 251, 0.23);
  border: 1px solid rgba(96, 128, 255, 0.21);
  border-radius: 8px;
  padding: 0.5rem;
}
.accordion-icon {
  width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.detail-tabs .nav-tabs {
  margin: 1.8rem 0 0.5rem 0;
  border: 1px solid #4b6efc;
  font-size: 14px;
  padding: 0.2rem;
  border-radius: 8px;
  display: flex;
}
.detail-tabs .nav-tabs .nav-item {
  flex: 1;
}
.detail-tabs .nav-tabs .nav-link {
  padding: 0.3rem 0.75rem;
  cursor: pointer;
  border: 1px solid transparent !important;
  text-align: center;
}
.detail-tabs .nav-tabs .nav-link:hover {
  background: #4b6efc21;
  border: 1px solid transparent !important;
  border-radius: 6px;
}
.detail-tabs .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border: 1px solid #4b6efc !important;
  background: #4b6efcbd;
  color: #ffffff;
  border-radius: 6px;
}
.user-activity {
  position: relative;
  z-index: 5;
  list-style-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10' fill='none'%3E%3Crect width='10' height='10' rx='5' fill='%23CECDFA'/%3E%3Crect x='1.42856' y='1.42857' width='7.14286' height='7.14286' rx='3.57143' fill='%236C63FF'/%3E%3C/svg%3E");
}
.user-activity-item {
  position: relative;
  z-index: 5;
  color: #212121;
}
.user-activity-item::before {
  content: ' ';
  background: #4b6efc2f;
  height: 22px;
  width: 1.5px;
  position: absolute;
  left: -13px;
  top: -9px;
  z-index: 2;
}
.user-activity-item::after {
  content: ' ';
  background: #4b6efc2f;
  height: 87.5%;
  width: 1.5px;
  position: absolute;
  left: -13px;
  top: 9px;
  z-index: 2;
}
.user-activity .user-activity-item:last-child::after {
  content: none;
}
